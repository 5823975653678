import { ValueProps } from '@rategravity/marketing-components';
import React from 'react';
import { howItWorksREideas } from '../../../data/content/real-estate';
import { metadata } from '../../../data/metadata/images';
import { ValuePropsWrapper } from '../../wrappers';

export const HowItWorks = () => {
  const howItWorksSubtext =
    'We use technology and dedicated human advisors to simplify the lending process. We deliver clarity and better outcomes for home buyers and their agents.';

  return (
    <ValuePropsWrapper>
      <ValueProps
        valueProps={howItWorksREideas}
        header="How It Works"
        ariaLabel="How it works"
        metadata={metadata}
        additionalText={howItWorksSubtext}
      />
    </ValuePropsWrapper>
  );
};
